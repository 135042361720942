import React, { useEffect, useState } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import Title from '../Title';
// import './FAQList.css'; 

const FAQList = ({ faqData, title = "FAQ" }) => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [isCursorActive, setIsCursorActive] = useState(false);
  const [ripple, setRipple] = useState(null);

  useEffect(() => {
    const moveCursor = (e) => {
      if (isCursorActive) {
        setCursorPos({ x: e.clientX, y: e.clientY });
      }
    };

    document.addEventListener("mousemove", moveCursor);
    return () => document.removeEventListener("mousemove", moveCursor);
  }, [isCursorActive]);

  // Ripple Effect on Click
  const handleClick = (e) => {
    setRipple({ x: e.clientX, y: e.clientY });
    setTimeout(() => setRipple(null), 600); // Remove ripple after animation
  };

  return (
    <section 
      className="faq-container"
      onMouseEnter={() => setIsCursorActive(true)}
      onMouseLeave={() => setIsCursorActive(false)}
      onClick={handleClick}
      style={{ marginTop: '50px', marginBottom: '20px' }}
    >
      {/* Custom Cursor (Only Visible Inside FAQList) */}
      {/* {isCursorActive && (
        <>
          <div 
            className="faq-custom-cursor" 
            style={{ left: `${cursorPos.x}px`, top: `${cursorPos.y}px` }}
          ></div>

         
          {ripple && (
            <div 
              className="faq-ripple"
              style={{
                left: `${ripple.x}px`,
                top: `${ripple.y}px`
              }}
            ></div>
          )}
        </>
      )} */}

      <Title title={title} />
      <Container style={{ marginBottom: '100px' }}>
        <Accordion defaultActiveKey="0">
          {faqData.map((item, index) => (
            <Card key={index}>
              <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                {item.title}{' '}
                <span className="accordion-icon">
                  <IoIosArrowDown />
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body>{item.desc}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Container>
    </section>
  );
};

export default FAQList;
