import React, { useContext } from 'react';
import { DataContext } from '../context';
import PageTitle from '../components/PageTitle';
import Team from '../components/Team';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import TwoColumnBlock from '../components/TwoBlockColumn';

function About() {
    const context = useContext(DataContext);

    // Check if context is loaded
    if (!context || !context.allData) {
        return <div>Loading...</div>;
    }

    const { team, footer } = context.allData;
    const { handleSearch, handleOffcanvas } = context;

    return (
        <>
            <Navbar handler={{ handleSearch, handleOffcanvas }} headerTop="true" />
            <PageTitle pageName="About Us" />
            
            <TwoColumnBlock
                heading=""
                title1="Who We Are"
                content1="We strive to be recognized as the go-to IT system integration and consulting partner. We offer cutting-edge Cloud and Infrastructure management services that drive our valued clients' efficiency, security, and scalability. With our customer-centric approach and passion for excellence, we aim to elevate businesses to new heights in the digital era."
                title2="Get Started Quickly"
                title3="Keep Costs Low"
                title31="Creative Ideas"
                title21="Scale Up with Confidence"
                desc1="Start projects swiftly with TRIVONT. Tailored solutions for developers and startups. Support at every step."
                desc2="Ensure cost-effectiveness with TRIVONT's transparent pricing. Competitive options without sacrificing quality."
                desc11="Explore innovation with TRIVONT. Dedicated team for unique solutions. Bringing ideas to life."
                desc21="Scale your operations confidently with TRIVONT Technologies. Our commitment to reliability means you can trust us to deliver a 99.99% uptime."
                buttonText1="Read More"
                showButton={true}
            />

            <Team data={team} classText="section-bg pt-120 pb-140" />
            <Footer data={footer} />
        </>
    );
}

export default About;
