// import React, { useState } from "react";
// import {
//   ComposableMap,
//   Geographies,
//   Geography,
//   Sphere,
//   Graticule,
//   Marker
// } from "react-simple-maps";
// import banglore from '../../images/mapimages/blore.png'
// import cheennai from '../../images/mapimages/chennai.png';
// import hyderbad from '../../images/mapimages/hyd.png';
// import singapore from '../../images/mapimages/singapore2 1.png'
// import texas from '../../images/mapimages/texas.png'
// import uae from '../../images/mapimages/saudiarabia20 1.png'
// const geoUrl = "map.json"; // Ensure this contains only continents

// const markers = [
//   { name: "Bangalore", coordinates: [77.5946, 12.9716], img: banglore, offset: { x: 0, y: 0 } },
//   { name: "Hyderabad", coordinates: [78.4867, 17.3850], img: hyderbad, offset: { x: 0, y: 0 } },
//   { name: "Chennai", coordinates: [80.3, 13.1], img: cheennai, offset: { x: -1.2, y: -4 } }, // Adjusted position
//   { name: "Singapore", coordinates: [103.8198, 1.3521], img: singapore, offset: { x: 0, y: 0 } },
//   { name: "Texas, USA", coordinates: [-99.9018, 31.9686], img:texas, offset: { x: 0, y: 0 } },
//   { name: "Dubai", coordinates: [55.2962, 25.276987], img: uae, offset: { x: 0, y: 0 } }
// ];

// const MapChart = () => {
//   const [hoveredMarker, setHoveredMarker] = useState(true);

//   return (
//     <div className="mapContainer">
//       <ComposableMap
//         projectionConfig={{ rotate: [-10, 0, 0], scale: 148 }}
//         style={{ width: "100%", height: "100%" }}
//       >
//         {/* Globe structure */}
//         <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
//         <Graticule stroke="#E4E5E6" strokeWidth={0.5} />

//         {/* Continents */}
//         <Geographies geography={geoUrl}>
//           {({ geographies }) =>
//             geographies.map((geo) => (
//               <Geography
//                 key={geo.rsmKey}
//                 geography={geo}
//                 fill="#2984ff"
//                 stroke="#FFF"
//                 style={{
//                   default: { outline: "none" },
//                   hover: { fill: "#1a65cc", transition: "0.3s" },
//                   pressed: { fill: "#0d4aa1" }
//                 }}
//               />
//             ))
//           }
//         </Geographies>

//         {/* Custom Markers */}
//         {markers.map(({ name, coordinates, img, offset }) => {
//           const adjustedCoordinates = [
//             coordinates[0] + offset.x,
//             coordinates[1] + offset.y
//           ];

//           return (
//             <Marker
//               key={name}
//               coordinates={adjustedCoordinates}
//               onMouseEnter={() => setHoveredMarker(name)}
//               onMouseLeave={() => setHoveredMarker(null)}
//             >
//               {/* White Marker */}
//               <g
//                 fill="none"
//                 stroke="#242424"
//                 strokeWidth="1"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 transform="translate(-12, -24) scale(0.6)"
//               >
//                 <circle cx="12" cy="10" r="3" fill="#242424" />
//                 <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
//               </g>

//               {/* Show card component when hovered */}
//               {hoveredMarker === name && (
//                 <foreignObject x="-30" y="-70" width="80" height="100">
//                   <div
//                     style={{
//                       width: "80px",
//                       backgroundColor: "#242424",
//                       borderRadius: "5px",
//                       padding: "5px",
//                       textAlign: "center",
//                       boxShadow: "0px 4px 8px rgba(0,0,0,0.3)",
//                       color: "#fff",
//                       fontSize: "12px",
                     
//                     }}
//                   >
//                     <img
//                       src={img || "default.png"} // Use city-specific image
//                       alt={name}
//                       style={{
//                         width: "60px",
//                         height: "60px",
//                         borderRadius: "50%",
//                         objectFit: "cover",
//                         marginBottom: "5px"
//                       }}
//                     />
//                     <div>{name}</div>
//                   </div>
//                 </foreignObject>
//               )}
//             </Marker>
//           );
//         })}
//       </ComposableMap>
//     </div>
//   );
// };

// export default MapChart;


import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  Marker
} from "react-simple-maps";
import banglore from '../../images/mapimages/blore.png'
import cheennai from '../../images/mapimages/chennai.png';
import hyderbad from '../../images/mapimages/hyd.png';
import singapore from '../../images/mapimages/singapore2 1.png'
import texas from '../../images/mapimages/texas.png'
import uae from '../../images/mapimages/saudiarabia20 1.png';
import Title from "../Title";
const geoUrl = "map.json"; // Ensure this contains only continents

const markers = [
  { name: "Bangalore", coordinates: [77.5946, 12.9716], img: banglore, offset: { x: 0, y: 0 } },
  { name: "Hyderabad", coordinates: [78.4867, 17.3850], img: hyderbad, offset: { x: 0, y: 0 } },
  { name: "Chennai", coordinates: [80.3, 13.1], img: cheennai, offset: { x: -1.2, y: -4 } }, // Adjusted position
  { name: "Singapore", coordinates: [103.8198, 1.3521], img: singapore, offset: { x: 0, y: 0 } },
  { name: "Texas, USA", coordinates: [-99.9018, 31.9686], img:texas, offset: { x: 0, y: 0 } },
  { name: "Dubai", coordinates: [55.2962, 25.276987], img: uae, offset: { x: 0, y: 0 } }
];

const MapChart = () => {
  const [hoveredMarker, setHoveredMarker] = useState(null);

  return (
    <>
     
   <Title 
  title="  Our Locations" 
  classText="title-shape title-shape-sky-blue text-center mb-0"
 subtitle="Build locally, scale globally."
/>

    <div className="mapContainer">
      <ComposableMap
        projectionConfig={{ rotate: [-10, 0, 0], scale: 210 }}
        style={{ width: "100%", height: "100%" }}
      >
        {/* Globe structure */}
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />

        {/* Continents */}
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#2984ff"
                stroke="#FFF"
                style={{
                  default: { outline: "none" },
                  hover: { fill: "#1a65cc", transition: "0.3s" },
                  pressed: { fill: "#0d4aa1" }
                }}
              />
            ))
          }
        </Geographies>

        {/* Render all markers except the hovered one */}
        {markers
          .filter(({ name }) => name !== hoveredMarker)
          .map(({ name, coordinates, img, offset }) => {
            const adjustedCoordinates = [
              coordinates[0] + offset.x,
              coordinates[1] + offset.y
            ];

            return (
              <Marker
                key={name}
                coordinates={adjustedCoordinates}
                onMouseEnter={() => setHoveredMarker(name)}
                onMouseLeave={() => setHoveredMarker(null)}
              >
                {/* White Marker */}
                <g
                  fill="none"
                  stroke="#242424"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(-12, -24) scale(1)"
                >
                  <circle cx="12" cy="10" r="3" fill="#242424" />
                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
              </Marker>
            );
          })}

        {/* Render hovered marker last to keep it on top */}
        {markers
          .filter(({ name }) => name === hoveredMarker)
          .map(({ name, coordinates, img, offset }) => {
            const adjustedCoordinates = [
              coordinates[0] + offset.x,
              coordinates[1] + offset.y
            ];

            return (
              <Marker
                key={name}
                coordinates={adjustedCoordinates}
                onMouseEnter={() => setHoveredMarker(name)}
                onMouseLeave={() => setHoveredMarker(null)}
              >
                {/* White Marker */}
                <g
                  fill="none"
                  stroke="#242424"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(-12, -24) scale(0.6)"
                >
                  <circle cx="12" cy="10" r="3" fill="#242424" />
                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>

                {/* Tooltip appears only for hovered marker */}
                <foreignObject x="-30" y="-70" width="80" height="100">
                  <div
                    style={{
                      width: "80px",
                      backgroundColor: "#242424",
                      borderRadius: "5px",
                      padding: "5px",
                      textAlign: "center",
                      boxShadow: "0px 4px 8px rgba(0,0,0,0.3)",
                      color: "#fff",
                      fontSize: "12px"
                    }}
                  >
                    <img
                      src={img || "default.png"} // Use city-specific image
                      alt={name}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginBottom: "5px"
                      }}
                    />
                    <div>{name}</div>
                  </div>
                </foreignObject>
              </Marker>
            );
          })}
      </ComposableMap>
    </div>
    </>
  );
};

export default MapChart;
