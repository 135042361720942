import React, { Component} from "react";

import Logo from "../images/Banner_Trivont_Light.svg";
import stickyLogo from "../images/banner_dark.svg";
import { BiHomeAlt } from "react-icons/bi"
import { Link, NavLink } from "react-router-dom";
import { FaChevronUp, FaChevronDown, FaTimes,FaHome } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import cloudDatabase from '../images/NavbarIcons/Group.png';
import cloudapp from '../images/NavbarIcons/cloudApp.png';
import cloudTrans from '../images/NavbarIcons/cloudTranstion.png';
import cloudmess from '../images/NavbarIcons/cloudMessagging.png';
import cloudPivate from '../images/NavbarIcons/cloudSecurity.png';
import cloudNet from '../images/NavbarIcons/cloudnetwork.png';
import cloudCompute from '../images/NavbarIcons/cloudCompute.png';
import cloudhost from '../images/NavbarIcons/cloudHosting.png';
import CloudStorage from '../images/NavbarIcons/stroage.png';
import cloudSolution from '../images/NavbarIcons/managed-solutions-svgrepo-com.svg'
//import image

import Drop from "./dropdown/drop";

class Navbar extends Component {
  _isMounted = false;

  state = {
    stickyClass: "",
    mobileNav: false,
  };
  myRef = React.createRef();

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
    // window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  
  handleScroll() {
    let lastScrollY = window.scrollY;

    if (lastScrollY >= 70 && this._isMounted) {
      this.setState((state, props) => ({
        stickyClass: "sticky fadeInDown animated fixed-top",
      }));
    } else {
      if (this._isMounted)
        this.setState((state, props) => ({
          stickyClass: "",
        }));
    }
  }

  funRef(e) {
    e.preventDefault();
    this.setState({
      mobileNav: !this.state.mobileNav,
    });
  }

  style = {
    color: "#5551EF",
  };
  constructor(props) {
    super(props);
    this.state = {
      dropVisible: false,
      anotherState: false,
      yetAnotherState: false,
      isOpen: false,
      isOpencom: true,
      isOpensol: false,
    };
  }

  handleButtonClick = () => {
    this.setState((prevState) => ({
      dropVisible: !prevState.dropVisible,
    }));
  };
  handleHover = () => {
    this.setState({ dropVisible: true, isOpen: true });
  };

  handleLeave = () => {
    this.setState({ dropVisible: false, isOpen: false });
  };
  handleAnotherAction = () => {
    this.setState((prevState) => ({
      anotherState: !prevState.anotherState,
    }));
  };
  toggleDropVisibility = () => {
    this.setState((prevState) => ({
      dropVisible: !prevState.dropVisible,
      isOpen: !prevState.isOpen,
    }));
  };
  handleYetAnotherAction = () => {
    this.setState((prevState) => ({
      yetAnotherState: !prevState.yetAnotherState,
    }));
  };
  // handleHoverAnother = () => {
  //   this.setState({ anotherState: true, isOpencom: false });
  // };

  handleHoverAnother = () => {
    this.setState(prevState => ({ isOpencom: !prevState.isOpencom }));
  };

  toggleAnotherState = () => {
    this.setState((prevState) => ({ anotherState: !prevState.anotherState }));
  };

  handleHoverYetAnother = () => {
    this.setState({ yetAnotherState: true, isOpensol: true });
  };

  handleLeaveYetAnother = () => {
    this.setState({ yetAnotherState: false, isOpensol: false });
  };

  toggleYetAnotherState = () => {
    this.setState((prevState) => ({
      yetAnotherState: !prevState.yetAnotherState,
    }));
  };

  render() {
    let headerTop = this.props.headerTop;
    const {
      dropVisible,
      anotherState,
      yetAnotherState,
      isOpen,
      isOpencom,
      isOpensol,
    } = this.state;

    const ProductSidbar = [
      {
        title: "Compute",
        description: "Virtual Machine",
        link: "/virtualMachine",
      },
      {
        title: "Kubernates",
        description: "Fully Managed Kubernates Cluster",
        link: "/kubernates",
      },
      {
        title: "Private Cloud",
        description: "Dedicated Private Cloud Services",
        link: "/dedicatedPrivateCloud",
      },
      {
        title: "Databases",
        description: "Database As a Service",
        link: "/database-services",
      },
      {
        title: "Software Development",
        description: "Containerized Applications",
        link: "/container",
      },
      {
        title: "Data and AI",
        description: "Unlock Insights and Drive Innovation with Data and AI Solutions",
        link: "/dataAndAI",
      },
      {
        title: "GPU Cloud",
        description: " Scalable Software Solutions with GPU Cloud",
        link: "/softwareAndGPU",
      },
      {
        title: "Hybrid Cloud",
        description: "Combine On-Premises Infrastructure with Cloud Services for Enhanced Flexibility",
        link: "/hybridCloud",
      }
      // { title: "Application Services", description: "Websites & Highly Scalable Applications", link: "/websiteHosting" }
    ];

    
    const ProductRows = [
      [
      {
        title: "Featured Solutions",
        icon:cloudSolution,
        links: [
          { label: "Compute", url: "/virtualMachine", desc: "Powerful virtual machine solutions" },
          { label: "DbaaS", url: "/database-services", desc: "Database as a Service solutions" },
          { label: "GPU Cloud", url: "/gpuCloud", desc: "Scalable GPU cloud services" },
          { label: "Kubernetes", url: "/kubernates", desc: "Fully managed Kubernetes clusters" },
          { label: "Private Cloud", url: "/dedicatedPrivateCloud", desc: "Dedicated private cloud services" },
        ],
      },
      {
        title: "Applications",
        icon:cloudapp,
        links: [
          { label: "Website Hosting", url: "/websiteHosting", desc: "Host your website on scalable and reliable infrastructure for maximum uptime" },
          { label: "Application Hosting", url: "/applicationHosting", desc: "Deploy and manage your applications with ease on our cloud platform" },
          { label: "Uptime Guaranteed", url: "/upTimeApplication", desc: "Ensure high availability and reliability for your applications with our uptime guarantee" },
        ],
      },
      {
        title: "Cloud Transition Services",
        icon:cloudTrans,
        links: [
          { label: "Cloud Migration Service", url: "/cloudMigration", desc: "Seamlessly migrate your applications and data to the cloud with expert assistance" },
          { label: "Cloud Disaster Recovery (DR) Services", url: "/cloudDR", desc: "Implement robust disaster recovery strategies to protect your business-critical data and applications" },
        ],
      },
      {
        title: "Compute",
        icon:cloudCompute,
        links: [
  
{ label: "Auto Scale Compute Nodes", url: "/autoScaleComputeNodes", desc: "Automatically scalable compute nodes for fluctuating workloads" },
 { label: "GPU Compute Cluster", url: "/GPUcomputeCluster", desc: "Scalable GPU compute clusters for high-performance computing" },          
 { label: "GPU Compute Nodes", url: "/GPUcomputeNode", desc: "Compute nodes with GPU acceleration capabilities" },
 { label: "Optimized Virtual Machine", url: "/optimizedVirtualMachine", desc: "Highly optimized virtual machine configurations" },
{ label: "Virtual Machine", url: "/virtualMachine", desc: "Efficient virtual machine solutions" },
         
         
         
        
        ],
      },
      {
        title: "Managed Databases",
        icon:cloudDatabase,
        links: [
          { label: "Cassandra", url: "/cassandra", desc: "Distributed NoSQL database designed for handling large amounts of data across multiple servers" },
          { label: "Cockroach", url: "/cockroachDB", desc: "Distributed SQL database built for global scale and survivability" },
          { label: "HBase", url: "/hbase", desc: "Distributed, scalable, and consistent NoSQL database for big data applications" },
          { label: "Kafka", url: "/kafka", desc: "Distributed streaming platform for building real-time data pipelines and applications" },
          { label: "MongoDB", url: "/mongoDB", desc: "Scalable and flexible NoSQL database management" },
          { label: "MySQL", url: "/mysql", desc: "Relational database management system for structured data storage and retrieval" },
          { label: "PostgreSQL", url: "/PostgreSql", desc: "Open-source relational database management system known for its reliability and robust features" },
          { label: "TiDB", url: "/tiDB", desc: "Distributed SQL database that combines the scalability of NoSQL and the consistency of SQL" },
        ],
      },
      {
        title: "Messaging Service",
        icon:cloudmess,
        links: [
          { label: "Corporate Email", url: "/corporateCommunication", desc: "Enterprise-grade email solutions for corporate communication" },
          { label: "Messaging Services (SMS)", url: "/messagingService", desc: "Send and receive SMS messages reliably with our messaging platform" },
          { label: "Small Business Email", url: "/smallBusinessEmail", desc: "Secure and reliable email hosting for small businesses" },
        ],
      },
      {
        title: "Networking",
        icon:cloudNet ,
        links: [
          { label: "Cloud Firewalls", url: "/VirtualFirewalls", desc: "Protect your cloud infrastructure with customizable firewall rules" },
          { label: "Cloud VPN", url: "/cloudVPN", desc: "Establish secure connections between your on-premises network and cloud resources" },
          { label: "DDoS Protection", url: "/DDOS", desc: "Mitigate distributed denial-of-service (DDoS) attacks to ensure service availability" },
          { label: "DNS", url: "/DNS", desc: "Manage your domain names and translate them into IP addresses" },
          { label: "Load Balancers", url: "/loadBalancer", desc: "Distribute incoming network traffic across multiple servers for improved performance and reliability" },
          { label: "Virtual Private Cloud", url: "/vpc", desc: "Securely connect your resources in a virtual network environment" },
        ],
      },
      {
        title: "Private Cloud",
        icon:cloudPivate   ,
        links: [
          { label: "Dedicated Private Cloud Services", url: "/dedicatedPrivateCloud", desc: "Secure and dedicated private cloud solutions" },
        ],
      },
      {
        title: "Storage",
        icon:CloudStorage,
        links: [
          { label: "Block Storage", url: "/blockStorage", desc: "Block-level storage solutions with high-performance capabilities" },
          { label: "File Storage", url: "/fileStorage", desc: "File-based storage solutions for easy data access" },
          { label: "Object Storage", url: "/objectStorage", desc: "Scalable object storage solutions for your data" },
          { label: "Private FTPES Storage", url: "/ftpesStorage", desc: "Private FTP over SSL storage solutions" },
          { label: "Secure Drop", url: "/secureDrop", desc: "Encrypted file storage with secure access controls" },
        ],
      },
      {
        title: "Web Hosting Solutions",
        icon:cloudhost,
        links: [
          { label: "Static Websites Hosting", url: "/staticWesiteHosting", desc: "Fast and reliable hosting for static websites and web applications" },
          { label: "WooCommerce Hosting", url: "/wooCommerce", desc: "Optimized hosting solutions for WooCommerce-powered online stores" },
        ],
      },
      // Remaining objects follow the same structure...}
    ]
    ];
    
    const dummyRows = [
      [
        {
          title: "Featured Product",
          links: [
            {
              url: "/cloudservices",
              label: "Trivont Cloud",
              description: "Cloud services tailored to meet your business needs",
            },
          ],
        },
        
      ],
    ];
  
  
    // const topValue = 90;
    return (
      <>
        <header className={`header ${headerTop ? "" : "fixed-top"}`}>
        
          <div
            className={`${this.state.stickyClass} ${
              headerTop ? "bg-white" : ""
            } header-main`}
          >
            <Container fluid>
              <Row className="position-relative">
                <Col lg={2} xs={5} className="d-flex align-items-center">
                  {/* Logo */}
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={headerTop ? stickyLogo : Logo}
                        className="main-logo"
                        style={{
                          height: "50px",
                        }}
                        alt="Logo"
                      />
                      <img
                        src={stickyLogo}
                        className="sticky-logo"
                        style={{ height: "50px" }}
                        alt="Sticky Logo"
                      />
                    </Link>
                  </div>
                  {/* End Logo */}
                </Col>

                <Col
                  lg={10}
                  xs={7}
                  className="d-flex align-items-center justify-content-end position-static"
                >
                <div className="nav-wrapper">
  {/* Menu Button for Mobile */}
  <div id="menu-button" onClick={() => this.setState({ mobileNav: !this.state.mobileNav })}>
   {this.state.mobileNav ?  <div><FaTimes/></div> : <span></span>}
  
  </div>
  {/* End Menu Button */}

  <div className={`nav-wrap-inner ${this.state.mobileNav ? "active" : ""}`} ref={this.myRef}>
  {/* <div className="nav-wrap-inner" ref={this.myRef}> */}
    <ul className="nav flex-column flex-md-row align-items-center justify-content-end">
      
      {/* Desktop View */}
      {!this.state.mobileNav ? (
        <>
          {/* Products Dropdown - Desktop */}
          <div className="Trivont_dropdown">
            <span className="dropdown-title">
              Products <FaChevronDown className="chevron-icon" />
            </span>
            <div className="dropdown-menu">
              <a href="/itautomation">Trivont Automation</a>
              <a href="/emailService">Trivont Mail</a>
              <a href="/trivonticloud">Trivont iCloud</a>
              <a href="/tvirtual">Trivont Virtualizer</a>
            </div>
          </div>
          {/* <Drop
                    visible={true}
                    title=""
                    sidebarContent={ProductSidbar}
                    rows={ProductRows}
                    showSidebarButtons={true}
                  /> */}

          {/* Solutions Dropdown - Desktop */}
          <div className="dropdown " onMouseEnter={this.handleHover} onMouseLeave={this.handleLeave}>
            <button className="dropbtn" onClick={this.toggleDropVisibility}>
              Solutions <FaChevronDown className="chevron-icon" />
            </button>
            {this.state.dropVisible && (
              <div className="dropdown-content">
                <div className="dunny">
                  <Drop
                    visible={this.state.dropVisible}
                    title=""
                    sidebarContent={ProductSidbar}
                    rows={ProductRows}
                    showSidebarButtons={true}
                  />
                </div>
              </div>
            )}
          </div>
                <a href="/"  className="home-icon d-flex align-items-center text-black">
                <BiHomeAlt size={20} />
                
              </a>
        </>
      ) : (
        <>
          {/* Mobile View */}
          <div className="TrivontMobile-container">
      {/* Products Dropdown */}
      <div className="TrivontMobile-dropdown">
        <input type="checkbox" id="TrivontMobile-products" className="TrivontMobile-toggle" />
        <label htmlFor="TrivontMobile-products" className="TrivontMobile-button">
          Products <FaChevronDown className="chevron" />
        </label>
        <div className="TrivontMobile-menu">
        <a href="/itautomation">Trivont Automation</a>
              <a href="/emailService">Trivont Mail</a>
              <a href="/trivonticloud">Trivont iCloud</a>
              <a href="/tvirtual">Trivont Virtualizer</a>
        </div>
      </div>

      {/* Solutions Dropdown */}
      {/* <div className="TrivontMobile-dropdown">
        <input type="checkbox" id="TrivontMobile-solutions" className="TrivontMobile-toggle" />
        <label htmlFor="TrivontMobile-solutions" className="TrivontMobile-button">
          Solutions <FaChevronDown className="chevron" />
        </label>
        <div className="TrivontMobile-menu">
          <a href="/mobile-solution1">Mobile Solution 1</a>
          <a href="/mobile-solution2">Mobile Solution 2</a>
        </div>
      </div> */}
      <div className="TrivontMobile-container">
      {ProductRows[0].map((category, index) => (
        <div className="TrivontMobile-dropdown" key={index}>
          {/* Checkbox for Pure CSS Toggle */}
          <input type="checkbox" id={`TrivontMobile-toggle-${index}`} className="TrivontMobile-toggle" />
          <label htmlFor={`TrivontMobile-toggle-${index}`} className="TrivontMobile-button">
            {category.title} <FaChevronDown className="chevron" />
          </label>

          {/* Dropdown Menu */}
          <div className="TrivontMobile-menu">
            {category.links.map((link, linkIndex) => (
              <a key={linkIndex} href={link.url}>
                {link.label}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>

    </div>
        </>
      )}
    </ul>
  </div>
</div>

                </Col>
               

              </Row>
            </Container>
          </div>
        </header>
      </>
    );
  }
}

export default Navbar;
