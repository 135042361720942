import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import Box from '../components/customComponents/box';
import BSsection4s from '../components/BSsection4';
import Columns2s from '../components/TwoColumn';
import './PagesCss.css'



function ApplicationHosting() {

    const context = useContext(DataContext);
    const { footer,Column4} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const ApplicationHostingFeatures = [
        {
          title: 'Automated Monitering of Critical Services',
          subtitle: 'Set up automatic uptime checks for your critical services, ensuring a quick response to issues and maintaining uninterrupted service.'
        },
        {
          title: 'Constant Endpoint Monitering on Any Cloud',
         subtitle: 'Monitor your assets 24/7 using a URL or IP address. Gain insights into performance, uptime, and discover patterns that optimize decision-making, save resources, and enhance customer satisfaction.'
        },
        {
          title: 'Global Uptime Monitoring',
          subtitle: 'Monitor uptime globally by checking up to four regions worldwide in every uptime check. Obtain the average global uptime at any time for comprehensive performance insights.'
        },
        {
          title: 'Alerts for Latency, Downtime, and SSL Expiry',
          subtitle: 'Receive timely alerts for latency issues, downtime, and SSL certificate expiry. Keep your SSL certificates active, stay protected, and be the first to know when your site experiences slowdowns or outages.'
        }
        
      ];

  

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True" />
            <CustomSection header="Application Hosting"
  content="Effortless Deployment, Auto-Scaling and Expert Support for Optimal Application Performance"/>       
        
        <Columns2s data={Column4}/>
        
        <Box data={ApplicationHostingFeatures}  boxHeight="300px"/>

        <BSsection4s
      heading="Ready to embark on your cloud computing adventure?"
  title1="Begin Your Cloud Journey"
  title2="Claim Your $300 Cloud Credit Today!"
  title3="Seeking Guidance?"
  desc1="If you're new to Trivont, kickstart your journey with our range of cloud products, and enjoy a complimentary $300 credit."
  desc2="Our experts are here to assist you in crafting the perfect solution or connecting you with the right partner for your requirements."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>
           
            <Footer data={footer} />
        </> 
    )
}

export default ApplicationHosting
