import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import CustomFeature from '../components/customComponents/customBoxes';
import FAQList from '../components/customComponents/faq';
import FourBlocks from '../components/blocksFour';
import imgData from '../components/banner/DDOS.png';


const DDOS = () => {
  const context = useContext(DataContext);
  const {  footer,DDOSbenefits } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  const featureData = {
    title: 'Features',
    desc: 'Key Features of Our DDoS Protection Services',
    items: [
      { title: 'Real-Time Threat Detection',
      
      desc: ' Detect and mitigate DDoS attacks in real-time with our advanced threat detection mechanisms, ensuring swift response to emerging threats and minimizing service disruption.' },
      { 
        title: 'Traffic Scrubbing', 
      
      desc: "Safeguard your network infrastructure with traffic scrubbing capabilities, filtering out malicious traffic and allowing only legitimate requests to reach your servers, ensuring uninterrupted service availability." },
      { 
        title: 'Scalable Infrastructure',
      
      desc: 'Scale your protection infrastructure dynamically to handle massive volumetric attacks and ensure continuous protection against DDoS threats, regardless of their size or complexity.'  },
          {
       
        title: 'Intelligent Mitigation',
     
       
        desc: ' Employ intelligent mitigation techniques to distinguish between legitimate and malicious traffic, preserving the integrity of your services while neutralizing attack vectors.'
    },
    {
        title: 'Comprehensive Reporting',
       
       
        desc: 'Gain insights into attack trends, traffic patterns, and mitigation effectiveness with comprehensive reporting and analytics tools, enabling informed decision-making and proactive threat management.'
    },
    
        // Add more features as needed
      ]
      ,
  };
  const faqList = [
    {
      title: "What is Cloud-Based DDoS Protection?",
      desc: "Cloud-based DDoS Protection is a complimentary, continuous service crafted to safeguard your cloud resources from various generalized, network-layer DDoS attacks. Its primary goal is to ensure uninterrupted operation for your applications and websites within the cloud environment."
    },
    {
      title: "Does data traffic leave the network for mitigation?",
      desc: "No, our Cloud-based DDoS Protection is seamlessly integrated into our cloud infrastructure, conducting mitigation within our network. This ensures robust protection without introducing additional latency."
    },
    {
      title: "Which network layers does Cloud-Based DDoS Protection support?",
      desc: "Cloud-Based DDoS Protection operates at both the Network (layer 3) and Transport (layer 4) levels of the OSI model. It does not provide protection at the Application layer (layer 7)."
    },
    {
      title: "What resources are covered by Cloud-Based DDoS Protection?",
      desc: "Cloud-Based DDoS Protection extends its coverage to cloud-based resources, including virtual machines, managed databases, load balancers, and assigned reserved IPs."
    },
    {
      title: "Is Cloud-Based DDoS Protection a paid service?",
      desc: "No, Cloud-Based DDoS Protection is offered free of charge to all users, providing robust security without additional costs in the cloud environment."
    }
  ];
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  {/* <Banner2 data={ hero2Data}/> */}
  <CustomSection header="DDoS Protection"
  subtitle=""
  content="Navigate with Confidence, Powered by DDoS Fast, Reliable, Secure Your Path to Seamless Domain Resolution"
  isRow={true}
  img={imgData}
  />
  
 
   <CustomFeature data={featureData} box1Height='300px'/>

   <FourBlocks data={DDOSbenefits} blockHeight="300px"/>

   <FAQList faqData={faqList}/>
   <Footer data={footer}/>
   
  
  </>
  )
}

export default DDOS