import React, { useContext } from 'react'
import { DataContext } from '../context'
import BSservices from '../components/BSservices';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import TabsComponent from '../components/sideNavigation';
import './PagesCss.css'
import FAQList from '../components/customComponents/faq';



function BlockStorage() {

    const context = useContext(DataContext);
    const { BSservice, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const tabsData = [
        { title: 'Scalability on Demand', desc: 'Scale your storage infrastructure seamlessly as your business grows, ensuring that you always have the storage capacity you need to support evolving data requirements and workload demands.' },
        { title: 'Optimized Performance', desc: 'Optimize performance for your applications and workloads with our high-performance Block Storage solution, delivering consistent and reliable storage performance for mission-critical operations.' },
        { title: 'Cost-Efficiency', desc: 'Maximize cost-efficiency with our Block Storage service, offering competitive pricing models and pay-as-you-go billing options to help you optimize storage costs and align expenses with usage patterns.' },
        { title: 'Operational Efficiency', desc: 'Streamline storage management tasks and reduce administrative overhead with our Block Storage solution, featuring intuitive management interfaces and automation capabilities for simplified storage operations.' },
        { title: 'Future-Proofing', desc: 'Future-proof your storage infrastructure with our Block Storage solution, providing a scalable and adaptable storage platform that can accommodate emerging technologies and evolving business needs.' }
    ];
    

 


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Block Storage"
  content="Unleash Power and Performance with Our Block Storage Solutions, Redefine Storage Excellence – Lightning-Fast Speeds, Exceptional Reliability, Infinite Scalability"
  isRow={false}/>       
         
          <BSservices data={BSservice} blockHeight="380px"/>
         

{/*          
          <TabsComponent
        title="Effortless Scalability with Unmatched Performance"
    subtitle="Benefits"
        defaultActiveKey="link1"
        tabs={tabsData}
      /> */}
      <FAQList faqData={tabsData}  title="Effortless Scalability with Unmatched Performance" /> 
       
           
           <BSsection4s
  heading="Enhance Your Cloud Infrastructure with Block Storage"
  title1="Expand Your Storage Capabilities"
  title2="High Performance Solutions"
  title3="Need Expert Guidance?"
  desc1="Explore our Block Storage solutions to expand your storage capabilities effortlessly. Whether you're scaling applications or requiring high-performance solutions, start your journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of cloud experts is dedicated to helping you implement the perfect Block Storage setup or connecting you with the right partners to optimize your storage infrastructure."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>



<br/>
<br/>
           
            <Footer data={footer} />
        </> 
    )
}

export default BlockStorage
