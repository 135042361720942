import React, { useState } from 'react';
import './customcomponent.css'; // Ensure this file includes necessary styles

const ImageWithPlaceholder = ({
  src,
  alt,
  shape = 'rect', // Options: 'rect' (rectangle), 'circle'
  width = '100px',
  height = '100px',
  animationDuration = '0.5s',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={`placeholder-wrapper ${isLoaded ? 'loaded' : ''}`}
      style={{
        width,
        height,
        borderRadius: shape === 'circle' ? '50%' : '5px',
        position: 'relative', // Ensure correct positioning of placeholder and image
      }}
    >
      {/* Placeholder */}
      <div className={`placeholder ${isLoaded ? 'hide' : ''}`} style={{ animationDuration }}>
        {/* Shimmer effect will run when the image is loading */}
      </div>

      {/* Image */}
      <img
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        style={{
          display: isLoaded ? 'block' : 'none', // Show image once it's loaded
          width: '100%',
          height: '100%',
          borderRadius: shape === 'circle' ? '50%' : '5px',
          transition: 'opacity 0.5s ease-in-out', // Smooth fade-in effect
          opacity: isLoaded ? 1 : 0, // Ensure image fades in once it's loaded
        }}
      />
    </div>
  );
};

export default ImageWithPlaceholder;
