import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Box from '../components/customComponents/box';
import TabsComponent from '../components/sideNavigation';
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';
 import '../pages/PagesCss.css'                    
import FAQList from '../components/customComponents/faq';



const GPUcomputeNode = () => {
  const context = useContext(DataContext);
  const {  footer } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 const gpuFeatures = [
    {
      "title": "High-Performance ",
      subtitle: "Unleash the power of cutting-edge graphics processing units for accelerated data processing and complex computations."
    },
    {
      "title": "Optimized for AI and Machine Learning",
      subtitle:"Tailored GPU solutions designed to enhance capabilities in AI, machine learning, and deep learning tasks."
    },
    {
      "title": "Future-Ready Computing",
      subtitle: "Stay ahead with a glimpse into the future of computing, enabling you to tackle data-intensive applications and advanced simulations."
    },
    {
      title: "Scalable GPU Clusters",
      subtitle: "Build scalable GPU clusters to meet the demands of evolving AI and deep learning requirements."
    },
    {
      title: "Innovation Unleashed",
      subtitle: "Experience enhanced capabilities that allow you to explore new horizons in innovation, research, and development."
    },
    {
      title: "Seamless Integration",
      subtitle: "Our GPU compute nodes seamlessly integrate into existing systems, ensuring a smooth and efficient workflow."
    },
    {
      title: "Advanced Simulation Capabilities",
      subtitle: "Unlock advanced simulation capabilities for realistic and intricate modeling scenarios."
    },
    {
      title: "Dedicated to Deep Learning",
      subtitle: "Tailor-made for deep learning tasks, our GPU solutions provide the computational power needed for training and inference."
    }
  ]
  
  const tabsData = [
    { title: 'Elevate User Engagement', desc: 'Harness elevated outbound data speeds with Premium CPU-Optimized Virtual Machine, ensuring an accelerated and seamless app experience.' },
    { title: 'Effortlessly Expand Your Capabilities', desc: 'With advanced CPUs, enhanced network speeds, and NVMe SSDs, effortlessly scale your applications to meet the demands of data-intensive workloads.' },
    { title: 'Optimize the Reliability of Your Application', desc: 'Deploying multiple Virtual Machines to drive your app, Premium CPU-Optimized Virtual Machine offers robust performance across the board, ensuring consistent excellence in user experiences.' },
    { title: 'Enhance User Engagement', desc: 'Leverage higher outbound data speeds in Premium CPU-Optimized Virtual Machine to provide a faster and smoother app experience.' },
    { title: 'Seamless Scaling', desc: 'The mix of newer CPUs, faster network speeds, and NVMe SSDs makes it easy to scale your apps for data-intensive workloads.' },
    { title: 'Consistent Performance', desc: 'Maximize the performance consistency of your apps when running multiple Virtual Machines, ensuring consistently superior experiences for your users.' }
];

 
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  
  <CustomSection header="GPU Compute Node"
  subtitle=""
  content="Unleash the Power of Tomorrow: GPU Compute Nodes for Revolutionary Computing. Transforming Ideas into Reality"
  isRow={false}
  />
  
   <Box data={gpuFeatures} title="Discover the powerful features that set us apart." boxHeight="290px"/>
 

   
          {/* <TabsComponent
        title="Effortless Scalability with Unmatched Performance"
        subtitle="Gpu"
        defaultActiveKey="link1"
        tabs={tabsData}
      />  */}
     <FAQList  faqData={tabsData}  title='Effortless Scalability with Unmatched Performance'  />


     <BSsection4s
  heading="Ready to Supercharge Your Computing with GPU Compute Nodes?"
  title1="Start Your GPU Compute Node Journey"
  title2="High-Performance Computing Solutions"
  title3="Need Expert Guidance?"
  desc1="Unlock the power of Trivont's GPU compute nodes. Begin your journey with our high-performance computing solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of computing experts is here to help you deploy and optimize GPU compute nodes or connect you with the right partner to meet your computational requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

   
   <Footer data={footer}/>
  
  </>
  )
}

export default GPUcomputeNode