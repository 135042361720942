import React, { useState } from "react";
import styled, { css, keyframes } from 'styled-components';
import "../dropdown/drop.css"
import '../../App.css'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DpMainContainer = styled.div`
  /* Your styles here */
  overflow: hidden;
  transition: transform 0.3s all ease-in;
  box-shadow: 2px 8px 8px 10px rgba(0, 0, 0, 0.15);
  z-index: 9000;
  position: fixed;
  width: 100%;
  left:2px;
  
  /* Animation styles */
  ${({ visible }) =>
    visible &&
    css`
      animation: ${fadeIn} 0.5s ease-in-out;
    `}
  @media only screen and (max-width: 600px) {
    display:none
  }
@media only screen and (max-width: 987px) {  
  
    display: none;  
  
}

  @media only screen and (min-width: 601px) and (max-width: 1024px) {
   
  }

  
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
  
  }
`;

const DpDropdownContainer = styled.div`
  /* Your styles here */
  max-width: 100%;
  display: flex;
  gap: 10px;
  max-height: 90vh;
  border: 1px solid #ccc;
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px;
  height: auto;
  background-color: #ffff;
  transition: transform 5s ease;
  justify-content: space-evenly;

  
  @media only screen and (max-width: 600px) {
    /* Flex direction and other styles for small screens */
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
   
    /* WebKit scrollbar styling */
    ::-webkit-scrollbar {
      width: 10px; /* Width of the scrollbar */
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the thumb */
      border-radius: 5px; /* Rounded corners */
    }
  
    /* Firefox scrollbar styling */
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 transparent; /* Color of the thumb and track */
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
  
  }
  @media only screen and (min-width: 1025px) { /* Added media query for larger screens */
  height: 70vh; /* Set a fixed height for larger screens */
}
  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    /* Adjust the styles here */
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;

// Define other styled components here

const CloseIconContainer = styled.div`
  /* Your styles here */
  position: absolute;
  width: 0;
  height: 0;
  display: none;
  cursor: pointer;
`;

const DpSidebar = styled.div`
  /* Your styles here */
  width: 400px;
  height: 100%;
  max-width: 400px;
  
  flex: 1;
  box-sizing: border-box;
  // padding: 50px;

 @media only screen and (min-width: 601px) and (max-width: 1024px) {
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling */
  /* Hide scrollbar for IE and Edge */
-ms-overflow-style: none;
scrollbar-width: none;

/* Hide scrollbar for WebKit */
::-webkit-scrollbar {
  display: none;
}
}

/* Styles for screens between 1025px and 1440px (small-screen desktops) */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  overflow-y: auto;
  overflow-x: hidden;
  /* Hide scrollbar for IE and Edge */
-ms-overflow-style: none;
scrollbar-width: none;

/* Hide scrollbar for WebKit */
::-webkit-scrollbar {
  display: none;
}
}

/* Styles for landscape orientation */
@media only screen and (orientation: landscape) {
  /* You can add additional styles for landscape mode here */
}

/* Modern Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
  background: transparent; /* Invisible by default */
}

::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.3); /* Subtle scrollbar color */
  border-radius: 10px;
  transition: background 0.3s ease-in-out;
}

::-webkit-scrollbar-track {
  background: transparent; /* Invisible track */
}

/* Show scrollbar only on hover */
:hover::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.7); /* Darker on hover */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.9); /* Even darker when hovered */
}
  
`;

const DpSidebarHeader = styled.div`
  /* Your styles here */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
   display:none
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    /* Adjust the styles here */
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    /* Adjust the styles here */
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;
// this is the side bar contnent
const DpSidebarBtnContainer = styled.div`
  /* Your styles here */
  width: 100%;
  color: #242424;
  background-color: transparent;
  border-radius: 12px;
  transition: background-color 0.3s ease-in;
  border: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;

  display: flex;

  font-weight: 600;
  text-align: left;
  
  /* Media query for mobile devices (up to 767px) */
  @media (max-width: 767px) {
    display: none;
  }

  /* Media query for tablet devices (768px to 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`;
const DPSidebarH1 = styled.h1`
font-size:24px
// `
// const DpSidebarBtn = styled.a`
// width: 100% !important;
// color: #242424 !important;
// background-color: transparent !important;
// border-radius: 12px !important;
// transition: background-color 0.3s ease-in !important;
// border: 0px !important;
// // display: flex !important;
// // flex-direction: column !important;
// box-sizing: border-box !important;
// padding: 10px !important;



// font-weight: 600 !important;
// text-align: left !important;
// text-decoration: none !important;

// &:hover {
//   background-color: #f2f2f2 !important;
//   cursor: pointer !important;
// }
//   @media only screen and (max-width: 600px) {
   
//   }

//   /* Styles for screens between 601px and 1024px (tablets) */
//   @media only screen and (min-width: 601px) and (max-width: 1024px) {
//     /* Adjust the styles here */
//   }

//   /* Styles for screens between 1025px and 1440px (small-screen desktops) */
//   @media only screen and (min-width: 1025px) and (max-width: 1440px) {
//     /* Adjust the styles here */
//   }

//   /* Styles for landscape orientation */
//   @media only screen and (orientation: landscape) {
//     /* Adjust the styles here */
//   }
// `;

const DpSidebarBtn = styled.a`
  width: 100% !important;
  color: #242424 !important;
  background-color: transparent !important;
  border-radius: 12px !important;
  transition: background-color 0.3s ease-in !important;
  border: 0px !important;
  box-sizing: border-box !important;
  padding: 10px !important;
  font-weight: 600 !important;
  text-align: left !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;

  img {
    margin-right: 10px;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  &:hover {
    background-color: #f2f2f2 !important;
    cursor: pointer !important;

    img {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media only screen and (max-width: 600px) {
    /* Adjust the styles here */
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    /* Adjust the styles here */
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    /* Adjust the styles here */
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;

const DpSidebarBtnTitle = styled.h2`
font-size: 20px;
  font-weight: 500;
  margin: 0;
  @media only screen and (max-width: 600px) {
    font-size: 16px
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    /* Adjust the styles here */
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    /* Adjust the styles here */
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;

const DpSidebarBtnDescription = styled.p`
font-size: 12px;
  margin: 0;
  color: rgb(77, 91, 124);
  display: initial;
 
`;


// const Accordion = styled.div`
//   /* Your styles here */
//   display: none;
// `;



const DpDpContainer = styled.div`
  /* Your styles here */
  flex: 1;
  width: 100%;
  overflow: hidden;
 
  @media only screen and (max-width: 600px) {
    overflow: scroll;
    

   

/* Hide scrollbar for IE and Edge */
-ms-overflow-style: none;
scrollbar-width: none;

/* Hide scrollbar for WebKit */
::-webkit-scrollbar {
  display: none;
}

/* Style the scrollbar for other browsers */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    overflow: scroll;
    display:none;

   

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    /* Hide scrollbar for WebKit */
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* Style the scrollbar for other browsers */
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    overflow: scroll;
   
   

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    /* Hide scrollbar for WebKit */
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* Style the scrollbar for other browsers */
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
  @media only screen and (min-width: 1025px) { /* Added media query for larger screens */
  overflow: scroll;

   

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  /* Hide scrollbar for WebKit */
  ::-webkit-scrollbar {
    display: none;
  }
  
  /* Style the scrollbar for other browsers */
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
`;

const DpContainer = styled.div`
  //  flex: 1;
  // width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
scrollbar-width: none;

  @media only screen and (max-width: 600px) {
    overflow: scroll;
    

   

/* Hide scrollbar for IE and Edge */
-ms-overflow-style: none;
scrollbar-width: none;

/* Hide scrollbar for WebKit */
::-webkit-scrollbar {
  display: none;
}

/* Style the scrollbar for other browsers */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    overflow: scroll;
    display:none;

   

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    /* Hide scrollbar for WebKit */
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* Style the scrollbar for other browsers */
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    overflow: scroll;

   

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    /* Hide scrollbar for WebKit */
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* Style the scrollbar for other browsers */
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;

const DpRow = styled.div`
width: 400px;
height: auto;
box-sizing: border-box;
padding: 10px;
display: flex;

  @media only screen and (max-width: 600px) {
    height: auto;
    justify-content: space-between;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 10px;
    height:400px
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }

`;

const DpColumn = styled.div`
 padding: 20px !important;
  text-align: center;
  height: auto;
  @media only screen and (max-width: 600px) {
    padding: 15px;
    justify-items: center;
  }

  /* Styles for screens between 601px and 1024px (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    /* Adjust the styles here */
  }

  /* Styles for screens between 1025px and 1440px (small-screen desktops) */
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    /* Adjust the styles here */
  }

  /* Styles for landscape orientation */
  @media only screen and (orientation: landscape) {
    /* Adjust the styles here */
  }
`;

const DpFooter = styled.div`
  /* Your styles here */
  height: 50px;
  width: 100%;
  display: flex;
`;

const DpPrimaryButton = styled.button`
  width: 100%;
  height: 49px;
  background-color: #fff;
  color: #0069ff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 0;
`;

const DpSecondaryButton = styled.button`
  width: 100%;
  height: 49px;
  background-color: #0069ff;
  color: #efefef !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 0;
`;
const DpColumnH1 = styled.h1`
color: rgb(0, 12, 43);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 22px;
  margin: 0px;
  text-transform: uppercase;
`;
const DpListConatiner = styled.ul`
 margin: 5px !important;
  text-align: left !important;
  list-style:none !important;
  display:flex !important;
  flex-direction: column !important;
  position:relative !important;
  width:100% !important;
`;
const ListItem = styled.li`
text-decoration: none !important;
  color: rgb(77, 91, 124) !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  margin-bottom :10px;
 display:flex;
 
`;
const ListitemAnchor = styled.a`
text-decoration: none !important;
color: rgb(77, 91, 124) !important;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  &:hover {
    color: #0069ff !important;
  }
`;
const DropdownContainer = styled.div`
  // opacity: 0;
  // transform: translateY(-20px); /* Adjust the initial translateY value as needed */
  // transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Adjust the duration as needed */
  // width:100%;
  // z-index:9999;
  // 
`;
const Drop = ({ visible, title, sidebarContent, rows, onClose, top,showSidebarButtons }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const [activeProductIndex, setActiveProductIndex] = useState(null);

  const handleProductClick = (index) => {
    setActiveProductIndex(activeProductIndex === index ? null : index);
  };
  const [activeTitle, setActiveTitle] = useState(rows[0][0].title);
  
  return (
    <>
    {visible ? (
      <DpMainContainer className="DpContainer" visible={true} top={top}>
        <DpDropdownContainer>
          <DpSidebar>
          {showSidebarButtons ? (
                <DpSidebarBtnContainer>
                  {rows.map((row, rowIndex) => (
                    row.map((column, colIndex) => (
                      <DpSidebarBtn
                        key={colIndex}
                        className={column.title === activeTitle ? 'active' : ''}
                        onMouseEnter={() => setActiveTitle(column.title)}
                      >
                         <img style={{marginRight:'10px'}} width='40' height='35' src={column.icon} alt="icons" />

                        {column.title}
                       
                      </DpSidebarBtn>
                    ))
                  ))}
                </DpSidebarBtnContainer>
              ) : null}

            {/* {rows.map((category, index) => (
              <Accordion key={index} defaultActiveKey="0" className="accordianMobile__View">
                {category.map((item, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
                      {item.title}
                      <span className="accordion-icon">
                        <IoIosArrowDown />
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={i.toString()}>
                      <Card.Body>
                        <ul>
                          {item.links.map((link, j) => (
                            <li key={j}>
                              <a href={link.url}>
                                <span style={{ fontSize: '20px', fontWeight: 600 }}>
                                  {link.label}
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            ))} */}
          </DpSidebar>

          <DpContainer className="gridView">
            {rows.map((row, rowIndex) => (
              <DpRow key={rowIndex}>
                {row.map((column, colIndex) => (
                  <DpColumn key={colIndex} style={{ display: column.title === activeTitle ? 'block' : 'none' }}>
                    <DpColumnH1>{column.title}</DpColumnH1>
                    <DpListConatiner>
                      {column.links.map((link, linkIndex) => (
                        <li style={{ marginBottom: '2px' }} key={linkIndex}>
                          <a href={link.url} style={{ display: 'flex', flexDirection: 'column' }}>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 600,
                              }}
                            >
                              {link.label}
                            </span>
                            <span style={{ marginBottom: '2px' }}>{link.desc}</span>
                          </a>
                        </li>
                      ))}
                    </DpListConatiner>
                  </DpColumn>
                ))}
               
              </DpRow>
            ))}

          </DpContainer>
          <DpSidebar >
          <div className="container-sfluid text-center py-5  hide-below-1021">
      <div className="">
        <h2 className="text-primary mb-3">Elevate Your Cloud Experience with Trivont Cloud</h2>
        <p className="text-secondary">
          Power your business with cutting-edge <strong>Infrastructure as a Service (IaaS)</strong> designed for 
          <strong> enterprises, MSPs, and cloud providers</strong>.  
        </p>
        
        
        <div className="d-flex justify-content-center gap-3">
         
        <a href="/contactus" style={{  
    display: "inline-block",  
    padding: "12px 12px",  
    fontSize: "16px",  
    fontWeight: "bold",  
    color: "#007bff",  
    border: "2px solid #007bff",  
    borderRadius: "5px",  
    textDecoration: "none",  
    transition: "all 0.3s ease",  
    boxShadow: "2px 2px 8px rgba(0, 123, 255, 0.2)",  
    cursor:'pointer'
  }}  >
  Schedule a Demo
</a>
        </div>
      </div>
    </div>
          </DpSidebar>
        </DpDropdownContainer>
        {/* <DpFooter>
          <DpPrimaryButton>See All Products</DpPrimaryButton>
          <DpSecondaryButton>See What's New at Trivont</DpSecondaryButton>
        </DpFooter> */}
      </DpMainContainer>
    ) : null}
  </>
  );
};

export default Drop;
