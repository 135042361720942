import React, { useRef } from 'react';
import Loading from './Loading';
import Title from './Title';
import { Container, Row, Col } from 'react-bootstrap';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

function Feature({ data, classText, blockHeight }) {
    const publicUrl = process.env.PUBLIC_URL + '/';
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger once when the component comes into view
        threshold: 0.1, // Trigger when 50% of the component is in view
    });
    
    const trail = useTrail(data.items.length, {
        from: { opacity: 0, transform: 'translatex(-50px)' },
        to: { opacity: inView ? 1 : 0, transform: inView ? 'translatex(0)' : 'translatex(-50px)' },
        config: { duration: 500 },
        delay: index => inView ? index * 600 : 0, // Delay each item in the trail based on its index
    });

    if (!data) {
        return <Loading />;
    }

    return (
        <section className={`${classText} feature`} ref={ref}>
            <Container >
                <Row>
                    <Col>
                        <Title title={data.title} subtitle={data.desc} />
                    </Col>
                </Row>
                <Row className="justify-content-center single-feature-wrap">
                    {trail.map((props, index) => (
                        <Col key={index}  lg={4} md={6} className="d-flex justify-content-center ">
                            <animated.div className="single-feature" style={{ ...props, height: blockHeight, width: "400px" }}>
                                <div className="feature-icon">
                                    <img width='50' src={publicUrl + data.items[index].img.url} alt={data.items[index].img.alt} />
                                </div>
                                <div className="feature-content">
                                <h3 >{data.items[index].title}</h3>
            <p >{data.items[index].desc}</p>
                                </div>
                            </animated.div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

Feature.defaultProps = {
    classText: 'pt-120 pb-90'
};

export default Feature;
