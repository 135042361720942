import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Title from "./Title";
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

function BentoGrid({ data, classText, styleTwo, styleThree, blockHeight }) {
    const [blockHeight1, setBlockHeight] = useState(blockHeight); // Default block height
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.1, // When 10% of the component is in view
    });

    useEffect(() => {
        const updateBlockHeight = () => {
            if (window.innerWidth <= 767) {
                setBlockHeight(blockHeight + "100px"); // Adjust block height for mobile
            } else {
                setBlockHeight(blockHeight);
            }
        };

        updateBlockHeight();
        window.addEventListener("resize", updateBlockHeight);

        return () => {
            window.removeEventListener("resize", updateBlockHeight);
        };
    }, [blockHeight]);

    if (!data || !data.items) {
        return <Loading />;
    }

    return (
        <section
            style={{ backgroundColor: "#0d1e67" }}
            className={`${classText} BSservice`}
            ref={ref}
        >
            <Container>
                <Row>
                    <Col>
                        <Title
                            subtitle={data.desc}
                            title={data.title}
                            classText={`${
                                styleTwo
                                    ? "title-shape title-shape-sky-blue text-center text-white"
                                    : styleThree
                                    ? "title-shape title-shape-c2 title-shape-style-three text-center text-white"
                                    : "title-shape text-center text-white"
                            }`}
                        />
                    </Col>
                </Row>

                {/* First Row */}
                <Row className="justify-content-center">
                    {[0, 1].map((index) => (
                        <Col
                            key={index}
                            lg={index === 0 ? 8 : 4}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-4"
                        >
                            <div
                                className={`BentoGrid ${styleTwo ? "style--two" : styleThree ? "style--three" : ""} 
                                    ${inView ? (index % 2 === 0 ? "animate-slide-in-left" : "animate-slide-in-right") : "opacity-0"}
                                `}
                                style={{
                                    height: blockHeight1,
                                }}
                            >
                                <div className="service-contents">
                                    <h3 style={{ marginBottom: "15px" }}>{data.items[index]?.title}</h3>
                                    <p>{data.items[index]?.desc}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

                {/* Second Row */}
                <Row className="justify-content-center">
                    {[2, 3].map((index) => (
                        <Col
                            key={index}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-4"
                        >
                            <div
                                className={`BentoGrid ${styleTwo ? "style--two" : styleThree ? "style--three" : ""} 
                                    ${inView ? (index % 2 === 0 ? "animate-slide-in-left" : "animate-slide-in-right") : "opacity-0"}
                                `}
                                style={{
                                    height: blockHeight1,
                                }}
                            >
                                <div className="service-contents">
                                    <h3 style={{ marginBottom: "15px" }}>{data.items[index]?.title}</h3>
                                    <p>{data.items[index]?.desc}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

BentoGrid.defaultProps = {
    classText: "pt-120 pb-90",
};

export default BentoGrid;
