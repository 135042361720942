import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import CustomFeature from '../components/customComponents/customBoxes';
import WCprocedure from '../components/WCBlock3';
import commercialHosting from '../components/banner/commercialHosting.png'

import './PagesCss.css'



function WooCommerce() {

    const context = useContext(DataContext);
    const { footer} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const featureData = {
        title: 'What you get when you implement cloud migration',
        desc: '',
        items: [
            {
                title: 'Enhanced Data Security',
                desc: 'Ensure the security of your data during migration and while residing in the cloud environment.',
              },
              {
                title: 'Optimized Performance',
                desc: 'Improve application performance by leveraging cloud infrastructure, ensuring faster response times.',
              },
              {
                title: 'Cost-Effective Solutions',
                desc: 'Access affordable pricing models tailored to your specific cloud migration needs.',
              }
           
          ]
          ,
      };

  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
            <CustomSection header="WooCommerce"
  content="Elevate Your Online Store with WooCommerce /excellence Unleash the power of E-commerce Innovation and Seamless Shopping Expriences"
  
  img={commercialHosting}
  isRow={true}/>       
        
        <CustomFeature data={featureData}/>
       
       

{/* <WCprocedure/> */}

<BSsection4s
  heading="Ready to Boost Your Online Store with WooCommerce?"
  title1="Start Your WooCommerce Journey"
  title2="Custom E-commerce Solutions"
  title3="Need Expert Guidance?"
  desc1="Take your online store to the next level with Trivont's WooCommerce services. Begin your journey with our customizable e-commerce solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of e-commerce experts is here to help you tailor the perfect WooCommerce setup or connect you with the right partner to enhance your online sales."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



            <Footer data={footer} />
        </> 
    )
}

export default WooCommerce
