import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import Columns2s from '../components/TwoColumn';
import BSservices from '../components/BSservices';
import './PagesCss.css'
import FourBlocks from '../components/blocksFour';
import compute from '../components/banner/fileStorage.png'


function FileStorage() {

    const context = useContext(DataContext);
    const {Column2, FSservice, footer , FSbenefits} = context.allData
    const { handleSearch, handleOffcanvas } = context;

   


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="File Storage"
  content="Unified File Sharing for Seamless Collaboration, Empower Your Team, Simplify Workflows, and Boost Productivity with Our Secure File Storage Solution"
  isRow={true}
  img={compute}/>       
        


           <Columns2s data={Column2}/>

          
           <BSservices data={FSservice} blockHeight="380px"/>
{/*           
           <FourBlocks data={FSbenefits} blockHeight="350px"/> */}



           <BSsection4s
  heading="Ready to Simplify Your File Storage?"
  title1="Start Your File Storage Journey"
  title2="Efficient Data Storage Solutions"
  title3="Need Expert Guidance?"
  desc1="Discover Trivont's robust file storage solutions designed to streamline your data management. Begin your journey with our efficient file storage solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of experts is here to assist you in implementing and optimizing your file storage strategy or connecting you with the right partner to meet your specific storage requirements."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>


<br/>
<br/>
           
            <Footer data={footer} />
        </> 
    )
}

export default FileStorage
