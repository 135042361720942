import React, { useContext } from 'react'
import { DataContext } from '../context'
import OSservices from '../components/OSservice';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import OSfeatures from '../components/OSfeatures';
import Box from '../components/customComponents/box';
import './PagesCss.css'

function ObjectStorage() {

    const context = useContext(DataContext);
    const { OSservice,footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;


    const OSCards = [
        {
          title: "Effortless Management",
          subtitle: "Simplify data management with our intuitive Object Storage solution. Enjoy seamless file organization, retrieval, and versioning for enhanced productivity."  },
        {
          title: "Cost-Effective Storage",
          subtitle: "Reduce storage costs and optimize resource utilization with our Object Storage service. Pay only for the storage capacity you need, eliminating the need for costly hardware investments and maintenance."
           },
        {
          title: "Scalable Performance",
          subtitle: "Our Object Storage solution maintains consistent performance as your data volumes expand. With scalable performance, you can access your data swiftly and reliably at all times."  },
        {
          title: "Data Insights and Analytics",
          subtitle: "Access valuable insights from your data with advanced analytics and reporting features in our Object Storage service. Drive innovation and informed decision-making with powerful data analysis tools."     }
      ];

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Object Storage"
  
            content="Unleash Infinite Potential with Our Object Storage Redefining Data Management Excellance"
            isRow={false}/>       
          
          <OSservices data={OSservice} blockHeight="510px"/>

        
         <Box data={OSCards} title=" Experience the Benefits of Object Storage" boxHeight="350px"/>
<br/>
   

           {/* <OSfeatures/>  */}

        <Footer data={footer} />
        </>
    )
    }

export default ObjectStorage