import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import BSservices from '../components/BSservices';
import Box from '../components/customComponents/box';
import './PagesCss.css'
import cockroachdb from '../components/banner/cockroachDB.png'



function Cockroach() {

    const context = useContext(DataContext);
    const { footer, CockroachFeatures } = context.allData
    const { handleSearch, handleOffcanvas } = context;

   
    const CockroachBenefits = [
        {
          title: "Global Reach",
          subtitle:"Expand your business reach and presence with CockroachDB's global cloud services, allowing you to serve customers and users from multiple regions and geographies with consistent and reliable database access."  },
        {
          title: "Data Durability",
          subtitle:"Protect your data against data center failures, hardware faults, and natural disasters with CockroachDB's distributed replication and automatic data redundancy features, ensuring data durability and disaster recovery capabilities." },
        {
          title: "Simplified Operations",
          subtitle: "Simplify database management tasks with CockroachDB's automated repair, rebalancing, and scaling mechanisms, reducing administrative overhead and improving operational efficiency."   },
        {
          title: "Compliance Readiness",
          subtitle:"Achieve regulatory compliance and data governance requirements with CockroachDB's support for data encryption, access controls, and audit logging, ensuring data security and integrity in accordance with industry standards."  }
      ];


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="CockroachDB"
            subtitle=''
  content="CockroachDB empowers organizations with reliable, transactional, and highly available databases designed for global cloud services."
  img={cockroachdb}
  isRow={true}
  />       
        


        <BSservices data={CockroachFeatures} blockHeight="340px"/>

           
           
    <Box data={CockroachBenefits} title=" Experience the Benefits of Trivont's CockroachDB Solutions"  boxHeight="410px"/>
    



    <BSsection4s
  heading="Ready to Transform Your Data Infrastructure?"
  title1="Start Your Journey with CockroachDB"
  title2="Explore Distributed SQL"
  title3="Need Expert Guidance?"
  desc1="Discover the power of CockroachDB for your data infrastructure needs. Whether you're new to distributed SQL or looking to scale, kickstart your journey with our robust solutions and enjoy a complimentary $300 credit."
  desc2="Our team of database experts is dedicated to helping you craft the perfect data solution or connecting you with the right partners to optimize your data infrastructure."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>


           
            <Footer data={footer} />
        </> 
    )
}

export default Cockroach
