import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';

import './PagesCss.css'

import websiteHosting from '../components/banner/WEBHOSTING (1).png'
import Box from '../components/customComponents/box';

function WebsiteHosting() {

    const context = useContext(DataContext);
    const { footer} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const featureData = {
        title: 'Key Features',
        desc: 'Discover the powerful features that set us apart.',
        items: [
            {
                title: "Up to 10 Gbps Outbound Data Speeds",
                desc: "Experience a significant boost in outbound network speeds – five times faster than their Regular counterparts – with the introduction of the new Premium CPU-Optimized Droplets, simplifying the delivery of top-notch digital experiences."
              },
              {
                title: "Reliable and Enduring Performance",
                desc: "Harness the power of the latest Intel® Xeon® CPU generations with Premium CPU-Optimized Droplets, ensuring unwavering application performance and reliability."
              },
             
              {
                title: "Dedicated CPU for Consistent Performance",
                desc: "Unlike shared CPU Droplets, dedicated CPU Droplets guarantee uninterrupted access to the full hyperthread, ensuring a consistently high level of performance."
              }
          // Add more feature items as needed
        ],
      };
    const   items= [
        {
            title: "Up to 10 Gbps Outbound Data Speeds",
            subtitle: "Experience a significant boost in outbound network speeds – five times faster than their Regular counterparts – with the introduction of the new Premium CPU-Optimized Droplets."
          },
          {
            title: "Reliable and Enduring Performance",
            subtitle: "Harness the power of the latest Intel® Xeon® CPU generations with Premium CPU-Optimized Droplets, ensuring unwavering application performance and reliability."
          },
         
          {
            title: "Dedicated CPU for Consistent Performance",
            subtitle: "Unlike shared CPU Droplets, dedicated CPU Droplets guarantee uninterrupted access to the full hyperthread, ensuring a consistently high level of performance."
          }
      // Add more feature items as needed
    ]

     
  

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True" />
            <CustomSection header="Web Hosting"
  content="Empower Your Online Presence with Unrivaled Reliability and Speed"
  img={websiteHosting}
  isRow={true}/>       
        
      
     
           

           <Box data={items } title= "Featured Services" />
            
       



      



           
            <Footer data={footer} />
        </> 
    )
}

export default WebsiteHosting
