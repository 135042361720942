import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ImageWithPlaceholder from './imagePlacholder'; // Make sure the path is correct

const FourBoxes = ({ data }) => {
  return (
    <Row className="justify-content-around   ">
      {data.map((item, index) => (
        <Col key={index} md={6} lg={3} className="mb-4  d-flex justify-content-center">
          <div
            className="custom_fourboxes"
            style={{
              borderRadius: '15px',
              padding: '15px',
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            <ImageWithPlaceholder
              src={item.imageUrl}
              alt={item.title}
              shape="circle" // or "rect"
              width="100px"
              height="100px"
            />
            <h4 style={{ lineHeight: '25px' }}>{item.title}</h4>
            <p>{item.subtitle}</p>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default FourBoxes;
