import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4'
import Columns2s from '../components/TwoColumn';
import Box from '../components/customComponents/box';
import FourBlocks from '../components/blocksFour'
import './PagesCss.css'



function SecureDrop() {

    const context = useContext(DataContext);
    const {Column3,  footer, SDuseCases } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const gpuFeatures = [
      
        {
          "title": "End-to-End Encryption",
          subtitle: "Protect your sensitive data with robust end-to-end encryption, ensuring that your files remain secure throughout their lifecycle"
        },
        {
          "title": "Strict Access Controls",
          subtitle:"Implement strict access controls to guarantee that only authorized users have the privilege to access and share confidential information."
        },
        {
          "title": "User-Friendly Interface",
          subtitle: "Experience the convenience of a user-friendly interface, designed for seamless navigation and effortless interaction similar to popular cloud-based platforms."
        },
        {
          title: "Confidential Information Safeguard",
          subtitle: "Safeguard your confidential information with SecureDrop, providing a secure storage environment that prioritizes the highest standards of protection."
        },
        {
          title: "Dropbox-Like Convenience",
          subtitle: "Enjoy the convenience of a Dropbox-like interface, combining user-friendliness with top-tier security for an unparalleled data security experience."
        }
        
      ]


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}  headerTop="True"  />
            <CustomSection header="Secure Drop"
  subtitle="Elevate Your Data Security"
  content="Experience Confidentiality in a User-Friendly Environment"
  isRow={false}/>       
        


           <Columns2s data={Column3}/>

           <Box data={gpuFeatures} title=" Features" subtitle="Discover the Features of Secure Drop" boxHeight="310px"/>

           <FourBlocks data={SDuseCases} blockHeight="400px"/>

           <BSsection4s
  heading="Ready to Securely Share Your Files?"
  title1="Start Your Secure Drop Journey"
  title2="Secure and Reliable File Transfers"
  title3="Need Assistance?"
  desc1="Experience the security of Trivont's Secure Drop. Begin your journey with our robust file transfer solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of security experts is here to help you implement the perfect secure file transfer system or connect you with the right partner to meet your security needs."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



           
            <Footer data={footer} />
        </> 
    )
}

export default SecureDrop
