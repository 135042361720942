import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from './Loading';
import Title from './Title';

function Solution({ data, classText, styleTwo }) {
  const [scrollY, setScrollY] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  // Set up scroll event listener for react-spring animation
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // React Spring animation for fade-in from bottom
  const fadeInAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(50px)',
    config: { duration: 800 },
  });

  // React Spring animation for the image
  const imageAnimation = useSpring({
    to: {
      transform: `scale(${1 - scrollY / 1000}) translateY(${scrollY * 0.5}px)`,
    },
    from: {
      transform: 'scale(1) translateY(0)',
    },
  });

  let publicUrl = process.env.PUBLIC_URL + '/';

  if (!data) {
    return <Loading />;
  }

  return (
    <animated.section
      ref={ref}
      style={fadeInAnimation}
      className={`${classText} solution ${styleTwo ? '' : 'min-vh-100'}`}
    >
      <Container>
        <Row>
          {/* Left Column with Image */}
          <Col lg={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="solution-img d-none d-xl-block">
              <animated.img
                style={imageAnimation} // Apply spring animation to the image
                src={publicUrl + data.images.mainImg.url}
                alt={publicUrl + data.images.mainImg.alt}
              />
            </div>
            <div className="solution-img-responsive d-xl-none">
              <img
                src={publicUrl + data.images.fullImg.url}
                alt={publicUrl + data.images.fullImg.alt}
              />
            </div>
          </Col>

          {/* Right Column with Content */}
          <Col lg={4}>
            <div className="solution-content">
              <Title
                title={data.title}
                subtitle={data.desc}
                classText="style_2 title-shape"
              />
              {data.items.map((item, index) => (
                <div key={index} className="single-solution media align-items-center">
                  <div className="content media-body">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </animated.section>
  );
}

export default Solution;

Solution.defaultProps = {
  classText: 'layer section-bg pt-120 pb-120',
  styleTwo: false,
};
