import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import Box from '../components/customComponents/box';
import Columns2s from '../components/TwoColumn';
import smallBusinessEmail from '../components/banner/smallBusinessEmail.png'

import './PagesCss.css'



function SmallBusinessEmail() {

    const context = useContext(DataContext);
    const { footer,Column7} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const SBFeatures = [
        {
          "title": "Custome Domain Options",
          subtitle: "Enhance your brand identity with custom domain options for professional and memorable email addresses."
         },
         {
          "title": "Efficent Inbox Management",
          subtitle:"Streamline your email workflow with efficient inbox management tools, making communication a breeze."
        },
        {
          "title": "Reliable Security Features",
          subtitle: "Ensure the safety of your communication with robust security features to protect your emails and sensitive information."
        },
        {
          "title": "Professional Image",
          subtitle: "Present a professional image to your customers and partners through polished and branded email communication."
        }
        
      ]


  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"   />
            <CustomSection header="Business Communication"
  content=" Empower Your Small Business with Professional Email Services"
  img={smallBusinessEmail}
  isRow={false}/>       

<Columns2s data={Column7}/>
<Box data={SBFeatures}  boxHeight="280px"/>

       
<BSsection4s
  heading="Ready to Elevate Your Email Communication?"
  title1="Start Your Email Services Journey"
  title2="Reliable and Secure Email Solutions"
  title3="Need Expert Assistance?"
  desc1="Discover the power of Trivont's email services. Kickstart your journey with our robust email solutions and enjoy a complimentary $300 credit to get you started."
  desc2="Our team of email experts is here to help you configure the perfect email setup or connect you with the right partner to enhance your communication strategy."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>



            <Footer data={footer} />
        </> 
    )
}

export default SmallBusinessEmail
