import { useState, useEffect } from "react";
import './customcomponent.css'
const useLazyImageLoader = (lowResSrc, highResSrc) => {
  const [src, setSrc] = useState(lowResSrc);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = highResSrc;

    img.onload = () => {
      setSrc(highResSrc);
      setIsLoaded(true);
    };
  }, [highResSrc]);

  return { src, isLoaded };
};

export default useLazyImageLoader;
