// import React from 'react';
// import './customcomponent.css'
// const BannerSection = ({ title, backgroundImage, color, height, customBackgroundSize, customBackgroundPosition, backgroundColor }) => {
//   const sectionStyle = {
//     width: '100%',
//     height: height,
//     backgroundImage: `url(${backgroundImage})`,
//     color: color,
//     backgroundSize: customBackgroundSize ? customBackgroundSize : 'cover',
//     backgroundPosition: customBackgroundPosition ? customBackgroundPosition : 'center center',
//     backgroundColor: backgroundColor // Add the backgroundColor property here
//   };

//   return (
//     <section className="d-flex justify-content-center align-items-center BannerCloudServices" style={sectionStyle}>
//       <div className="text-center">
//         <h1 className='' style={{ color: color }}>{title}</h1>
//       </div>
//     </section>
//   );
// };

// export default BannerSection;

// import React from "react";
// import "./customcomponent.css";
// import useLazyImageLoader from "./lazyImage";


// const BannerSection = ({
//   title,
//   backgroundImage,
//   lowResBackgroundImage, // Accept the low-resolution background image as a prop
//   color,
//   height,
//   customBackgroundSize,
//   customBackgroundPosition,
//   backgroundColor,
// }) => {
//   // Use the lazy image loader hook
//   const { src, isLoaded } = useLazyImageLoader(lowResBackgroundImage, backgroundImage);

//   const sectionStyle = {
//     width: "100%",
//     height: height,
//     backgroundImage: `url(${src})`, // Use the dynamically loaded image
//     color: color,
//     backgroundSize: customBackgroundSize ? customBackgroundSize : "cover",
//     backgroundPosition: customBackgroundPosition ? customBackgroundPosition : "center center",
//     backgroundColor: backgroundColor,
//     transition: "filter 0.3s ease-in-out, opacity 0.3s ease-in-out", // Smooth transition
//     filter: isLoaded ? "blur(0)" : "blur(10px)", // Blur effect based on load state
//     opacity: isLoaded ? 1 : 0.5, // Faded effect before the high-res image loads
//   };

//   return (
//     <section
//       className="d-flex justify-content-center align-items-center BannerCloudServices"
//       style={sectionStyle}
//     >
//       <div className="text-center">
//         <h1 className="" style={{ color: color }}>
//           {title}
//         </h1>
//       </div>
//     </section>
//   );
// };

// export default BannerSection;


import React from "react";
import "./customcomponent.css";
import useLazyImageLoader from "./lazyImage";

const BannerSection = ({
  title,
  backgroundImage,
  lowResBackgroundImage, // Accept the low-resolution background image as a prop
  color,
  height,
  customBackgroundSize,
  customBackgroundPosition,
  backgroundColor,
}) => {
  // Use the lazy image loader hook
  const { src, isLoaded } = useLazyImageLoader(lowResBackgroundImage, backgroundImage);

  return (
    <div
      className="lazy-image-container d-flex justify-content-center align-items-center"
      style={{
        height: height,
        backgroundColor: backgroundColor,
      }}
    >
      {/* Background image */}
      <img
        src={src}
        alt="Background"
        className={`lazy-image ${isLoaded ? "loaded" : ""}`}
        style={{
          backgroundSize: customBackgroundSize || "cover",
          backgroundPosition: customBackgroundPosition || "center center",
        }}
      />
      
      {/* Text content */}
      <div className="lazy-image-content">
        <h1 style={{ color: color }}>{title}</h1>
      </div>
    </div>
  );
};

export default BannerSection;
