
import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';

import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import unix_imageone from '../images/icons/team.png'
import unix_imagetwo from '../images/icons/virtual-database-access.png'
import unix_imagethree from '../images/icons/data-storage.png'
import unix_imagefour from '../images/icons/terminal.png'
import unix_imagefive from '../images/icons/technical-support.png'
import unix_imagesix from '../images/icons/computer-networking.png'
import sevr from '../images/BANNERS/server.jpg';
import lowsevr from '../images/BANNERS/result_server.jpg'
const UnixServer = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData

  const { handleSearch, handleOffcanvas } = context;
  
  const enterpriseManagementData = [
    {
      title: "SAN & NAS Services",
      subtitle: "Reduce Business Risks with Comprehensive SAN and NAS Solutions",
      image:unix_imagesix,
      description: "Mitigate business risks and ensure data availability with our comprehensive SAN and NAS services."
    },
    {
      title: "Comprehensive IT Support Services",
      subtitle: "Ensuring Smooth Operations with Reliable IT Support",
     image:unix_imagefive,
      description: "Keep your organization running smoothly with comprehensive IT support services tailored to your needs."
    },
    {
      title: "Unix and Linux Management",
      subtitle: "Efficient Management of IBM Solaris and RHEL",
      image:unix_imagefour,
      description: "Optimize operations with expert management of Unix and Linux systems, including IBM Solaris, Red Hat Enterprise Linux, and other distributions."
    },
    {
      title: "Maximize Employee Engagement with Application Management",
      subtitle: "Enhance Efficiency and Productivity Through Application Management",
     image:unix_imageone,
      description: "Empower your workforce and maximize engagement with comprehensive application management solutions."
    },
    {
      title: "Storage Virtualization Solutions",
      subtitle: "Embrace IT Transformation with Advanced Virtualization",
     image:unix_imagetwo,
      description: "Stay ahead of the curve with storage virtualization solutions that optimize IT infrastructure and enhance scalability."
    },
    {
      title: "Server and Storage Planning and Design",
      subtitle: "Strategic Planning for Optimized IT Infrastructure",
      image:unix_imagethree,
      description: "Maximize efficiency and performance with strategic server and storage planning and design services tailored to your organization's needs."
    }
  ];
  
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  {/* <Banner2 data={ hero2Data}/> */}
  
  <BannerSection
        title="Server & Storage"
        backgroundColor="#030710"
        color="#fff"
        height="400px"
        backgroundImage={sevr}
        lowResBackgroundImage={lowsevr}
      />
      

<SliderHeroSection backgroundImage='#fff'  title="Automation & AI" subtitle="Simplify your IT operations" 
  textColor='#242424' isDefault={true} featureTitle='Optimized Server and Storage Solutions: Tailored to Your Needs'
   featureSubtitle="Understanding the unique requirements of each organization is essential for customizing server and storage solutions. In today's era of rapid data growth fueled by social media and digital advancements, enterprises face immense pressure to adapt " >
    
  </SliderHeroSection>
 
  <MigrationJumbotron title='Comprehensive Server and Storage Solutions' description='Efficient  Server and Storage Management' />  
  

   <IconBox items={ enterpriseManagementData}/> 

    



    <Footer data={footer} />
  
  </>
  )
}

export default UnixServer