import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import IconBox from '../components/iconbox';
import BannerSection from '../components/customComponents/bannerSection';
import backgrounBanner from '../images/BANNERS/icloud.jpeg';
import serviceboxOne from '../images/icons/Scalability1.png';
import serviceboxTwo from '../images/icons/icons8-kubernetes-250.png';
import serviceboxThree from '../images/icons/digitalization.png';
import serviceboxFour from '../images/icons/24-7.png';
import serviceboxFive from '../images/icons/bill.png';
import serviceboxSix from '../images/icons/cloud_7701094.png';
import serviceboxSeven from '../images/icons/engineering_3079165.png';
import serviceboxEight from '../images/icons/user.png';
import serviceboxTen from '../images/icons/global.png';
import serviceboxEle from '../images/icons/cloud-computing_1136779.png';
import serviceboxTwe from '../images/icons/cloud (2).png';
import banner from '../images/BANNERS/TrivontCloudPng.png'
import bannerImage from  '../images/BANNERS/cloudServicestwo.png';
import lowbannerImage from  '../images/BANNERS/result_cloudServicestwo.png';
import BSsection4s from '../components/BSsection4';

const TrivontIcloud = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    
   
      
    const cloudServiceProvidersInfo = [
        {
          title: "Tailored for Cloud Service Providers",
          subtitle: "Robust and Flexible Foundation",
          image:serviceboxEle,
          description: "Trivont iCloud is designed specifically for companies with existing datacenters or hosted environments. Our platform provides a robust and flexible foundation that enables you to deliver a comprehensive suite of cloud services to your customers, matching the capabilities of leading providers like AWS, Azure, and OCI."
        },
        {
          title: "Comprehensive IaaS Capabilities",
          subtitle: "Full Range of IaaS Services",
          image:serviceboxTwe,
          description: "Offer your customers a full range of IaaS services with Trivont . Our platform supports the deployment, management, and scaling of virtual machines, storage solutions, and network resources, ensuring you can meet the diverse needs of your clientele."
        },
        {
          title: "User-Friendly Interface",
          subtitle: "Efficient and Effective Management",
          image:serviceboxEight,
          description: "Our interface is as intuitive and user-friendly as those of major cloud providers. This means you and your customers can manage resources efficiently and effectively, without the steep learning curve."
        },
        {
          title: "Advanced Networking and Security",
          subtitle: "Secure, High-Performance Network Solutions",
          image:serviceboxThree,
          description: "Trivont iCloud includes native support for advanced networking features such as load balancers, Layer 2 (L2) and Layer 3 (L3) firewalls, Site-to-Site VPNs, and Virtual Private Clouds (VPCs). These capabilities ensure that you can offer secure, high-performance network solutions to your customers."
        },
        {
          title: "High Availability and Disaster Recovery",
          subtitle: "End-to-End High Availability",
          image:serviceboxFour,
          description: "Ensure business continuity for your customers with our end-to-end high availability (HA) and fault tolerance features. Trivont iCloud also supports VM replication across multiple sites, providing robust disaster recovery options."
        },
        {
          title: "Billing and Invoicing Dashboards",
          subtitle: "Comprehensive Management Tools",
          image:serviceboxFive,
          description: "Manage your services with ease using our comprehensive billing and invoicing dashboards. These tools allow you to track usage, generate invoices, and manage subscriptions, making it simple to run your cloud business."
        },
        {
          title: "White Labeling and Rebranding",
          subtitle: "Customized Experience",
          image:serviceboxSix,
          description: "Trivont iCloud supports white labeling and rebranding, allowing you to deliver a customized experience under your own brand. This ensures that your customers see a seamless, cohesive service offering that aligns with your company's identity."
        },
        {
          title: "Cost-Effective and Scalable",
          subtitle: "Flexible Licensing Model",
          image:serviceboxOne,
          description: "Our licensing model is based on support subscriptions, with costs as low as $999 per annum, regardless of the number of cores or sockets. This flexible pricing structure makes it easy for you to scale your offerings without worrying about escalating costs."
        },
        {
          title: "Kubernetes as a Service (KaaS)",
          subtitle: "Simplify Container Orchestration",
          image:serviceboxTwo,
          description: "Simplify container orchestration with Trivont iCloud’s native Kubernetes as a Service (KaaS) offerings. Deploy Kubernetes clusters effortlessly and provide your customers with a reliable and scalable container platform."
        },
        {
          title: "Seamless Integration and Management",
          subtitle: "Effortless Migration",
          image:serviceboxSeven,
          description: "Transitioning to Trivont iCloud from platforms like VMware, Hyper-V, AWS, Azure, or OCI is seamless and straightforward. Your team can manage Trivont iCloud without any additional training, thanks to our intuitive design and comprehensive support."
        },
        {
          title: "All-in-One Solution",
          subtitle: "Complete Cloud Solution",
          image:serviceboxTen,
          description: "Trivont iCloud combines virtualization, backup, networking, and disaster recovery into a single, unified platform. This integration allows you to offer a complete cloud solution to your customers, eliminating the need for multiple vendor solutions."
        }
      ];
      
    
   
    
    return (
    <>
    <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
   
    <BannerSection
title="iCloud Manager"
backgroundColor="#003059"
color="#fff"
height="400px"
backgroundImage={bannerImage}
customBackgroundPosition='center center   '
lowResBackgroundImage={lowbannerImage}
/>
   
        
  
  <SliderHeroSection backgroundImage='#fffff
  ' 
   
   textColor='#242424' isDefault={true} featureTitle='Trivont iCloud: The Ideal Solution for Cloud Service Providers
   '
     featureSubtitle="Welcome to Trivont iCloud, your go-to platform for delivering top-tier Infrastructure as a Service (IaaS). Whether you're an enterprise, a medium-sized business, a small cloud service provider, or a Managed Service Provider (MSP), Trivont iCloud equips you with all the tools you need to offer competitive and reliable cloud services."  imageUrl={banner}  showImage={true} >
    '
   
    </SliderHeroSection>
   
    
    
  
     <IconBox title='Why Choose Trivont iCloud?' items={ cloudServiceProvidersInfo}/> 
  


  
     <BSsection4s
  heading="Contact Us Today"
  title1="Ready to elevate your cloud services?"
  title2="Learn more about Trivont iCloud"
  title3="Schedule a demo"
  desc1="Contact us to learn more about how Trivont iCloud can transform your offerings and schedule a demo to see our product in action."
  desc2="Let Trivont iCloud be the foundation of your cloud service business."
  buttonText1="Start Your Trivont Journey"
  buttonText2="Contact Our Virtualization Experts"
/>

      <Footer data={footer} />
    
    </>
  )
}

export default TrivontIcloud