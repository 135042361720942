import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';


import Box from '../components/customComponents/box';

import BSservices from '../components/BSservices';
import BSsection4s from '../components/BSsection4';
import DPC from '../components/banner/Private Cloud.png'


const CloudDprivateCloud = () => {
  const context = useContext(DataContext);
  const {  footer, PCservice } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  
  const dedicatedPrivateCloudFeatures = [
    {
      title: 'Ultimate Privacy',
      subtitle: 'Enjoy the utmost privacy with our Dedicated Private Cloud Services, offering exclusive access to a dedicated compute, storage, and network infrastructure tailored specifically for your organization.'
     },
    {
      title: 'Robust Security',
     subtitle: 'Rest assured with robust security measures integrated into our private cloud environment, safeguarding your sensitive data and applications against external threats and vulnerabilities.'
    },
    {
      title: 'High Performance',
      subtitle: 'Experience high-performance computing with our dedicated infrastructure, optimized to deliver superior speed, agility, and responsiveness for your critical workloads.'
    },
    {
      title: 'Scalability',
      subtitle: 'Scale your computing resources seamlessly to accommodate evolving business needs, ensuring that your infrastructure grows in tandem with your organizations expansion '
      },
      {
        title: 'Managed Infrastructure',
        subtitle:' Benefit from hassle-free infrastructure management with our dedicated private cloud services, allowing you to focus on strategic initiatives while we handle the complexities of maintenance and optimization.'
        }
    // Add more features as needed
  ];
  

  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
  
  <CustomSection header="Dedicated Private Cloud"
  subtitle=""
  content="Unlock Unparalleled Privacy, Security, and Performance"
  isRow={true}
  img={DPC}
  />
 
  
   <Box data={dedicatedPrivateCloudFeatures} title="Key Features" boxHeight="360px"/>
   

   <BSservices data={PCservice} blockHeight="320px"/>

   <BSsection4s
  heading="Unlock the Power of Dedicated Private Cloud"
  title1="Secure Your Private Cloud"
  title2="Tailored Solutions"
  title3="Need Expert Guidance?"
  desc1="Experience the benefits of our Dedicated Private Cloud solutions. Whether you're securing sensitive data or requiring high-performance computing, start your journey with our customized solutions and enjoy a complimentary $300 credit."
  desc2="Our team of cloud experts is dedicated to helping you implement the perfect Dedicated Private Cloud environment or connecting you with the right partners for your specific requirements."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>

   <Footer data={footer}/>
   
  
  </>
  )
}

export default CloudDprivateCloud