import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import CustomSection from '../components/banner/Banner';
import Footer from '../components/Footer';
import CustomFeature from '../components/customComponents/customBoxes';
import FAQList from '../components/customComponents/faq';

import BSsection4s from '../components/BSsection4';



const CloudMigration = () => {
  const context = useContext(DataContext);
  const {  footer } = context.allData
  const { handleSearch, handleOffcanvas } = context;
  
 
  
  
 
  const featureData = {
    title: 'What you get when you implement cloud migration',
    desc: '',
    items: [
        { title: 'Enhanced Data Security', desc: 'Ensure the security of your data during migration and while residing in the cloud environment.' },
        { title: 'Optimized Performance', desc: 'Improve application performance by leveraging cloud infrastructure, ensuring faster response times.' },
        { title: 'Cost-Effective Solutions', desc: 'Access affordable pricing models tailored to your specific cloud migration needs.' }
      ]
      ,
  };
  const cloudMigrationFAQ = [
    {
      title: "What is Cloud Migration?",
      desc: "Cloud migration involves the process of moving digital assets, such as data, applications, and services, from on-premises infrastructure to a cloud computing environment. It enables organizations to leverage the benefits of cloud technology."
    },
    {
      title: "How does Cloud Migration work?",
      desc: "Cloud migration works by transferring data, applications, and other business elements from local servers to cloud infrastructure. This process often involves planning, testing, and executing the migration to ensure a smooth transition to the cloud environment."
    },
    {
      title: "Why is Cloud Migration important?",
      desc: "Cloud migration is crucial for organizations seeking enhanced scalability, flexibility, and cost-efficiency. It allows businesses to adapt to changing demands, improve resource utilization, and take advantage of the latest cloud technologies."
    },
    {
      title: "What types of considerations are important for Cloud Migration?",
      desc: "Cloud migration involves considerations such as data security, compatibility with cloud platforms, and efficient resource allocation. Planning, assessment, and a well-defined strategy are essential to ensure a successful migration to the cloud."
    },
    {
      title: "Is Cloud Migration suitable for businesses of all sizes?",
      desc: "Yes, Cloud Migration is adaptable and suitable for businesses of all sizes. Whether a small startup or a large enterprise, organizations can benefit from cloud migration by optimizing their IT infrastructure, improving collaboration, and gaining a competitive edge."
    }
  ];
  
  
  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True" />
  <CustomSection header="Cloud Migration"
  subtitle=""
  content="Seamless Cloud Migration Solutions Elevate Your Business to New Heights with Effortless Cloud Transition"
  />
  
 
   <CustomFeature data={featureData}/>



  
  



   <FAQList faqData={cloudMigrationFAQ}/>
   <BSsection4s
      heading="Ready to embark on your cloud computing adventure?"
  title1="Begin Your Cloud Journey"
  title2="Ready to migrate to the cloud?"
  title3="Seeking Guidance?"
  desc1="If you're new to Trivont, kickstart your journey with our range of cloud products, and enjoy a complimentary $300 credit."
  desc2="Our experts are here to assist you in crafting the perfect solution or connecting you with the right partner for your requirements."
  buttonText1="Start For Free"
  buttonText2="Contact Our Sales Team"
/>
   <Footer data={footer}/>
   
  
  </>
  )
}

export default CloudMigration