import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import CustomSection from '../components/banner/Banner';
import BSsection4s from '../components/BSsection4';
import Box from '../components/customComponents/box';
import Columns2s from '../components/TwoColumn';
import messagingService from '../components/banner/SMS.png'

import './PagesCss.css'



function MessagingService() {

    const context = useContext(DataContext);
    const { footer,Column9} = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const MSFeatures = [
        {
          "title": "Instant Communication",
          subtitle: "Harness the power of instant communication through our Messaging Services, providing quick and effective connections with your audience."
        },
        {
          "title": "Reliable SMS Delivery",
          subtitle:"Ensure your messages are delivered reliably with our SMS messaging service, offering a robust and scalable platform for reaching your customers."
        },
        {
          "title": "Versatile Usage",
          subtitle: "Whether it's notifications, promotions, or alerts, our Messaging Services cater to various business needs, providing versatility in your communication strategies."
        },
        {
          "title": "Detailed Reporting and Analytics",
          subtitle: "Benefit from comprehensive reporting and analytics tools that empower you to optimize messaging campaigns, ensuring precision and effectiveness."
        },
        {
          "title": "Scalable Platform",
          subtitle: "Our Messaging Services offer a scalable platform, making it suitable for businesses of all sizes, from small enterprises to large corporations."
        },
        {
          "title": "Tailored for Business",
          subtitle: "Trivont understands the diverse communication needs of businesses. Our Messaging Services are designed to cater to these needs effectively and professionally."
        }
        
      ]


  


    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            <CustomSection header="Messaging Services(SMS)"
  content=" Seamless SMS Solutions for Instant and Effective Communications"
  img={messagingService}
  isRow={false}/>       

<Columns2s data={Column9}/>
<Box data={MSFeatures}  boxHeight="300px"/>

       
<BSsection4s
  heading="Ready to Transform Your Communication Strategy?"
  title1="Start Your Messaging Journey"
  title2="Customizable Messaging Solutions"
  title3="Need Expert Advice?"
  desc1="Discover how Trivont's powerful messaging platform can revolutionize your communication. Get started with a complimentary $300 credit and explore our comprehensive suite of messaging services."
  desc2="Our dedicated experts are available to help you design a messaging solution tailored to your unique needs, or to connect you with the perfect partner to drive your success."
  buttonText1="Get Started for Free"
  buttonText2="Contact Our Sales Team"
/>

            <Footer data={footer} />
        </> 
    )
}
export default MessagingService
