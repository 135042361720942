import React from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import "./customcomponent.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

const Box = ({ data, title, subtitle, boxHeight, coltype }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(-100px)",
    config: { duration: 800 },
  });

  return (
    <animated.div ref={ref} style={{ ...springProps }}>
      <section style={{ marginTop: "50px", marginBottom: "20px" }}>
        <Container fluid className="text-center">
          <div style={{ marginBottom: "65px" }}>
            <h2 style={{ marginBottom: "17px" }}>{title}</h2>
            <p>{subtitle}</p>
          </div>

          <Row className="align-items-center">
            {data.map((item, index) => {
              // Check if it's the last column and total columns are odd
              const isLastOddColumn = data.length % 2 !== 0 && index === data.length - 1;

              return (
                <Col
                  key={index}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={isLastOddColumn ? 12 : coltype ? coltype : 6} // Set col-12 for last odd item
                  className={`d-flex justify-content-center ${isLastOddColumn ? "text-center" : ""}`}
                >
                  <div
                    className="box-container"
                    style={{ height: boxHeight, marginRight: "15px" }}
                  >
                    <h1 className="box-title">{item.title}</h1>
                    <p className="box-subtitle">{item.subtitle}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </animated.div>
  );
};

export default Box;
